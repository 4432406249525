import React, {Component} from 'react';
import {connect} from "react-redux";

import Header from "../../components/Header/container/Header";
import Sidebar from "../../components/Sidebar/container/Sidebar";
import dateFormat from "dateformat";
import moment from "moment";
import SettingsMenuWidget from "../../components/SettingsMenuWidget/container/SettingsMenuWidget";
import SettingsWidget from "../../components/SettingsWidget/container/SettingsWidget";
import * as Constants from "../../components/Common";

import {
    checkSessionValidityAction,
    handleCancelSubAction,
    openDeleteModalAction
} from "../../actions/authActions";
import {
    getUserCalendarInfoAction,
    updateUserInfoRequestAction,
    updateUserPasswordRequestAction
} from "../../actions/userActions";

import {
    calendarSyncRequestAction,
    googleSyncAction,
    outlookSyncAction
} from "../../actions/calendarSyncActions";

import {closeToastAction} from "../../actions/modalActions";
import scriptLoader from 'react-async-script-loader'
import Loader from "react-loader-spinner";

import tickIcon from "../../assets/tick-icon-blue.png";

import queryString from "query-string";
import DeleteModal from "../../components/DeleteModal/container/DeleteModal";
import ProfilePictureModal from "../../components/ProfilePictureModal/container/ProfilePictureModal";

import Axios from 'axios';
import {toast, ToastContainer} from "react-toastify";
import {home_url} from "../../components/Common";
import "./Settings.css";

let scope;
let STRIPE = null;

const notifySuccess = () =>
    toast.success('Your information has been updated successfully', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const notifySubscriptionCancellation = () =>
    toast.info('Please wait while we update your plan', {
        position: "top-right",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const notifyFailure = () =>
    toast.error('Something went wrong, please try again', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

class Settings extends Component {
    constructor(props) {
        super(props);

        scope = this;
        this.state = {
            calendarCode: "",
            dropDownMenuVisible: true,
            windowWidth: window.innerWidth,
            profileActive: true,
            calendarActive: false,
            accountDetailsActive: false,
            settingsFirstName: !props.auth.userLoading ? props.auth.user.firstname : "",
            settingsFirstNameError: '',
            settingsLastName: !props.auth.userLoading ? props.auth.user.lastname : "",
            settingsLastNameError: '',
            settingsCompany: !props.auth.userLoading ? props.auth.user.company : "",
            settingsCompanyError: '',
            settingsJobTitle: !props.auth.userLoading ? props.auth.user.role : "",
            settingsJobTitleError: '',
            settingsPassword: '',
            settingsPasswordError: '',
            settingsNewPassword: '',
            settingsNewPasswordError: '',
            settingsNewPasswordConfirm: '',
            settingsNewPasswordConfirmError: '',
            googleCalendarEmail: '',
            googleCalendarSynced: false,
            outlookCalendarEmail: '',
            outlookCalendarSynced: false,
            switchChecked: false,
            selectedPlan: null,
            selectedPlanAmount: null,

        };

        this.handleUpgradeAccountClick = this.handleUpgradeAccountClick.bind(this);
        this.updatePaymentDetails = this.updatePaymentDetails.bind(this);
        const value = queryString.parse(this.props.location.search);
        const token = value.session_id;
        const planid = value.plan;
        if (!Constants.isEmpty(token)) {
            this.updatePaymentDetails(token, planid, undefined)
        }
    }

    updatePaymentDetails = function (token, planid, callbackFunction) {
        const authToken = 'Bearer ' + localStorage.getItem('authToken');
        var object = {};
        object.emailId = Constants.getCookie(Constants.COOKIES_ENUM.EMAILID);
        object.method = 1;
        object.planId = planid;
        object.token = token;
        object.stripe_cus_id = "";
        object.stripe_sub_id = "";

        console.log("update payment data " + JSON.stringify(object));

        Axios.post('https://api.live.zoimeet.com/payment/updatesubscription', object, {
            headers: {
                "Authorization": authToken
            }
        })
            .then(function (response) {
                Constants.setSessionStorage(Constants.SESSIONSTORAGE_ENUM.SUBSCRIPTIONSTATUS, 1);
                if (window.location.search !== "") window.location.search = "";
                this.props.history.push(home_url);
            })
            .catch();
    }

    handleUpgradeAccountClick = function () {
        this.setState({
            selectedPlan: 6,
            selectedPlanAmount: 15
        }, function () {
            console.log('KIMO', "STATE CHANGED");
            this.proceedPurchase();
        })
    };

    handleCancelPayment = async function () {
        this.props.handleCancelSub();
    };

    proceedPurchase = async function (event) {
        const authToken = 'Bearer ' + localStorage.getItem('authToken');
        var data = {};
        //debugger;
        data.planId = this.state.selectedPlan;
        data.price = this.state.selectedPlanAmount;
        Axios.post('https://api.live.zoimeet.com/payment/session', data,
            {
                headers: {
                    "Authorization": authToken
                }
            }).then(function (response) {
            console.log("checking.................", response);
            STRIPE.redirectToCheckout({
                sessionId: response.data.paymentsession.id,
            });
        });
    };

    logOutUser = () => {
        localStorage.removeItem("authToken");
        window.location.href = (Constants.login_url);
    };

    handleResize = (e) => {
        this.setState({windowWidth: window.innerWidth})
    };

    handeClick = () => {
        if (!this.state.dropDownMenuVisible) {
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            dropDownMenuVisible: !prevState.dropDownMenuVisible
        }));
    };

    handleOutsideClick = (e) => {
        if (this.node.contains(e.target)) {
            return;
        }

        this.handeClick();
    };

    update = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        scope.setState({[name]: value});
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        if (localStorage.getItem("authToken") !== null) {
            this.props.checkSessionValidity();
            if (localStorage.getItem("calendarActive") === "true") {
                this.setState({
                    profileActive: false,
                    calendarActive: true,
                    accountDetailsActive: false,
                    calendarCode: this.props.location.search
                });
            }
        } else
            window.location.href = (Constants.login_url);
    }


    componentWillReceiveProps({isScriptLoaded, isScriptLoadSucceed}) {
        if (isScriptLoaded && !this.props.isScriptLoaded) { // load finished
            if (isScriptLoadSucceed) {
                STRIPE = window.Stripe('pk_live_GSEHvFTgKbH0k2TMH8KQoP6S00bcQGsYkQ');
            } else
                this.props.onError()
        }
    }

    render() {
        const {windowWidth, profileActive, calendarActive, accountDetailsActive} = this.state;
        const {auth} = this.props;

        if (auth.showToast &&
            !auth.somethingWentWrong &&
            auth.userInfoUpdated) notifySuccess();

        if (auth.showToast &&
            auth.somethingWentWrong &&
            !auth.userInfoUpdated) notifyFailure();

        if (auth.subscriptionCanceled)
            notifySubscriptionCancellation();

        return (

            <div>

                <Sidebar
                    page={"settings"}
                />

                <Header
                    page={"settings"}
                />

                {auth.showProfilePictureModal &&
                <ProfilePictureModal/>
                }

                {auth.showDeleteModal &&
                <DeleteModal/>
                }

                <ToastContainer
                    position="top-right"
                    autoClose={10000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover/>

                <div className={windowWidth > 1024 ?
                    "settings-div main-container-body px-3 pt-3 d-md-flex" :
                    "settings-div-mobile main-container-body w-90 px-3 pt-3 d-md-flex"
                }>

                    {!auth.userLoading &&
                    <div className="card box-shadow border-0 settings-sidenav me-3">

                        <h2 className="fw-b f-blue f-12 px-4 py-4 align-items-center mb-0">
                            Your Preferences
                        </h2>

                        <p
                            onClick={() => {
                                this.setState({
                                    profileActive: true,
                                    calendarActive: false,
                                    accountDetailsActive: false
                                }, () => closeToastAction());
                                localStorage.removeItem("calendarActive");
                                // this.props.closeToast();
                            }}
                            className={profileActive ?
                                "text-decoration-none f-dark d-flex align-items-center justify-content-start is-active" :
                                "text-decoration-none f-dark d-flex align-items-center justify-content-start"
                            }
                            style={{cursor: "pointer"}}
                        >
                            <span className="px-4 py-3 fw-b f-11">Profile Information</span>
                        </p>

                        <p
                            onClick={() => {
                                this.setState({
                                    profileActive: false,
                                    calendarActive: true,
                                    accountDetailsActive: false
                                }, () => closeToastAction());
                                localStorage.setItem("calendarActive", "true");
                            }}
                            className={calendarActive ?
                                "text-decoration-none f-dark d-flex align-items-center justify-content-start is-active" :
                                "text-decoration-none f-dark d-flex align-items-center justify-content-start"
                            }
                            style={{cursor: "pointer"}}
                        >
                            <span className="px-4 py-3 fw-b f-11">Calender Connections</span>
                        </p>

                        <p
                            onClick={() => {
                                this.setState({
                                    profileActive: false,
                                    calendarActive: false,
                                    accountDetailsActive: true
                                }, () => closeToastAction());
                                localStorage.removeItem("calendarActive");
                                // this.props.closeToast();
                            }}
                            className={accountDetailsActive ?
                                "text-decoration-none f-dark d-flex align-items-center justify-content-start is-active" :
                                "text-decoration-none f-dark d-flex align-items-center justify-content-start"
                            }
                            style={{cursor: "pointer"}}
                        >
                            <span className="px-4 py-3 fw-b f-11">Account Details</span>
                        </p>

                        <div className="signout f-14 pb-4 mx-auto d-none d-md-inline-block">

                            <p
                                style={{cursor: "pointer"}}
                                className="f-medium text-decoration-none"
                                onClick={() => this.logOutUser()}
                            >
                                Sign out
                            </p>
                        </div>
                    </div>
                    }

                    {(profileActive && !auth.userLoading) &&
                    <div className="w-100 h-auto">
                        <SettingsWidget
                            active={"profile"}
                        />

                        <SettingsWidget
                            active={"password"}
                        />
                    </div>
                    }

                    {(calendarActive && !auth.userLoading) &&
                    <div className="w-100 d-inline-block">
                        <SettingsWidget
                            calendarCode={this.state.calendarCode}
                            active={"calendar"}
                        />
                    </div>
                    }

                    {
                        accountDetailsActive &&
                        <div className={windowWidth > 1024 ?
                            "w-75 d-inline-block fixed" :
                            "w-100 d-inline-block fixed"
                        }>
                            <div className="row">
                                <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-6 mb-0 mb-lg-4 mb-lg-0"
                                >
                                    <div className="card w-100 box-shadow border-0 mb-3">
                                        <div className="p-4">
                                            <h2 className="fw-b pb-4 f-14">Account Details</h2>
                                            <table className="table f-medium plan-table-header">
                                                <thead className="f-11 plan-table-header">
                                                <tr className="row px-3 plan-table-header">
                                                    <th
                                                        scope="col"
                                                        style={{borderTopWidth: "0", borderBottomWidth: "0"}}
                                                        className="col-8 align-self-start border-bottom ps-0"
                                                    >
                                                        Your Plan
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        style={{borderTopWidth: "0", borderBottomWidth: "0"}}
                                                        className="col-4 align-self-end border-bottom"
                                                    >
                                                        Fee
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody className="f-11">
                                                <tr className="row px-3">
                                                    <td className="col-8 ps-0">
                                                        <div className="align-self-start py-2">
                                                            {auth.user.subscription.plan &&
                                                            auth.user.subscription.plan
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className="col-4 align-self-end">
                                                        <div className="py-2">
                                                            ${auth.user.subscription.price &&
                                                        auth.user.subscription.price
                                                        }/Month
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <div className="px-1">
                                                <p className="f-11 mb-4">
                                                    <span className="fw-b">Current Period:&nbsp;</span>

                                                    {dateFormat(
                                                        moment(
                                                            moment.utc(
                                                                new Date(auth.user.subscription.start_time).toString()
                                                            )
                                                        ).local(),
                                                        'ddd dd, mmm yyyy', true)}
                                                    &nbsp;-&nbsp;
                                                    {dateFormat(
                                                        moment(
                                                            moment.utc(
                                                                new Date(auth.user.subscription.end_time).toString()
                                                            )
                                                        ).local(),
                                                        'ddd dd, mmm yyyy', true)}
                                                </p>
                                            </div>
                                            <button
                                                disabled={(auth.user.subscription.fk_plan === 2)}
                                                className={(auth.user.subscription.fk_plan === 2) ?
                                                    "btn btn-outline-secondary f-12 btn-md-h fw-b rounded-1 mt-2 disabled" :
                                                    "btn btn-outline-secondary f-12 btn-md-h fw-b rounded-1 mt-2"
                                                }
                                                onClick={() => this.handleCancelPayment()}
                                            >
                                                <span className="px-3">Cancel Plan</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-6 mb-0  mb-lg-0 ps-lg-0 pe-lg-2"
                                >
                                    <div className="card w-100 box-shadow border-0 mb-3">
                                        <div className="p-4">
                                            <h2 className="fw-b pb-4 f-14">Ready to go Pro? It's just $15/month</h2>
                                            <div className="row">
                                                <div className="col-12 col-lg-6">
                                                    <ul className="m-0 p-0">
                                                        <li
                                                            className="d-flex align-items-center justify-content-start mb-3"
                                                        >
                                                            <img src={tickIcon} alt=""
                                                                 className="me-2"/>
                                                            <span className="f-11">Unlimited Minutes</span>
                                                        </li>
                                                        <li
                                                            className="d-flex align-items-center justify-content-start mb-3"
                                                        >
                                                            <img src={tickIcon} alt=""
                                                                 className="me-2"/>
                                                            <span className="f-11"
                                                            >Unlimited Highlights</span
                                                            >
                                                        </li>
                                                        <li
                                                            className="d-flex align-items-center justify-content-start mb-3"
                                                        >
                                                            <img src={tickIcon} alt=""
                                                                 className="me-2"/>
                                                            <span className="f-11"
                                                            >Calendar Connections</span
                                                            >
                                                        </li>
                                                        <li
                                                            className="d-flex align-items-center justify-content-start mb-3"
                                                        >
                                                            <img src={tickIcon} alt=""
                                                                 className="me-2"/>
                                                            <span className="f-11">Highlight Triggers</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-12 col-lg-6">
                                                    <ul className="m-0 p-0">
                                                        <li
                                                            className="d-flex align-items-center justify-content-start mb-3"
                                                        >
                                                            <img src={tickIcon} alt=""
                                                                 className="me-2"/>
                                                            <span className="f-11"
                                                            >Live tanscripts</span
                                                            >
                                                        </li>
                                                        <li
                                                            className="d-flex align-items-center justify-content-start mb-3"
                                                        >
                                                            <img src={tickIcon} alt=""
                                                                 className="me-2"/>
                                                            <span className="f-11"
                                                            >Workspace Integrations</span
                                                            >
                                                        </li>
                                                        <li
                                                            className="d-flex align-items-center justify-content-start mb-3"
                                                        >
                                                            <img src={tickIcon} alt=""
                                                                 className="me-2"/>
                                                            <span className="f-11">Premium Accuracy</span>
                                                        </li>
                                                        <li
                                                            className="d-flex align-items-center justify-content-start mb-3"
                                                        >
                                                            <img src={tickIcon} alt=""
                                                                 className="me-2"/>
                                                            <span className="f-11">Quality Support</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <button
                                                disabled={(auth.user.subscription.fk_plan !== 2)}
                                                className={(auth.user.subscription.fk_plan !== 2) ?
                                                    "btn btn-orange w-100 f-12 btn-md-h fw-b rounded-1 mt-2 disabled" :
                                                    "btn btn-orange w-100 f-12 btn-md-h fw-b rounded-1 mt-2"
                                                }
                                                onClick={() => this.handleUpgradeAccountClick()}
                                            >
                                                <span className="px-3">Upgrade My Account</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-100 d-inline-block">
                                <div className="card w-100 box-shadow border-0 mb-3">
                                    <div className="p-4">
                                        <h2 className="fw-b mb-3 f-14">Delete Account</h2>
                                        <div className="row  ">
                                            <div className="col-12 col-lg-9 align-self-start f-11">
                                                By deleting your account you delete all your profile information
                                                and meeting data from our servers and includes all provided
                                                personal information and meeting recordings. By deleting your
                                                account you will not be able to retrieve your information.
                                            </div>
                                            <div className="col-12 col-lg-3 py-2 align-self-end">
                                                <button
                                                    onClick={() => this.props.openDeleteModal()}
                                                    className="btn btn-outline-warning f-11 btn-md-h fw-b rounded-1"
                                                >
                                                    <span className="px-3">Delete Account</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                {false &&
                <div className="settings-div">

                    {(auth.showToast && auth.userInfoUpdated && !auth.userInfoUpdating) &&
                    <div className="toast-outer info-success">
                        <section>
                            Your information has been updated.
                            <button
                                onClick={() => this.closeModal()}
                                className="close-success"
                            >x</button>
                        </section>
                    </div>
                    }

                    {(auth.showToast && !auth.userInfoUpdated && !auth.userInfoUpdating) &&
                    <div className="toast-outer info-failure">
                        <section>
                            Something went wrong, please try again.
                            <button
                                onClick={() => this.closeModal()}
                                className="close-failure">
                                x</button>
                        </section>
                    </div>
                    }

                    <SettingsMenuWidget
                        active={"account"}
                    />

                    {auth.userLoading ?
                        <Loader
                            type="ThreeDots"
                            color="#bbb"
                            height={8}
                            width={100}
                        />
                        :
                        <SettingsWidget
                            active={"account"}
                            user={auth.user}
                            {...this.props}
                        />
                    }

                    {!auth.userLoading &&
                    <div className="log-out-div">
                        <p>
                            Log Out of your Zoi Meet Account
                        </p>

                        <button
                            className="log-out-button"
                            onClick={() => this.logOutUser()}
                        >
                            LOG OUT
                        </button>
                    </div>
                    }
                </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    widget: state.widget,
    calendarSync: state.calendarSync,
    calendars: state.calendarReducer
});

const mapDispatchToProps = (dispatch) => ({
    handleCancelSub: () => dispatch(handleCancelSubAction()),
    checkSessionValidity: () => dispatch(checkSessionValidityAction()),
    closeToast: () => dispatch(closeToastAction()),
    updateUser: (email, firstName, lastName, companyName, jobTitle) => dispatch(updateUserInfoRequestAction(email, firstName, lastName, companyName, jobTitle)),
    updatePassword: (email, password, newPassword) => dispatch(updateUserPasswordRequestAction(email, password, newPassword)),
    googleSyncAction: (syncPayload) => dispatch(googleSyncAction(syncPayload)),
    outlookSyncAction: (syncPayload) => dispatch(outlookSyncAction(syncPayload)),
    calendarSyncRequestAction: (data) => dispatch(calendarSyncRequestAction(data)),
    getUserCalendarInfo: () => dispatch(getUserCalendarInfoAction()),
    openDeleteModal: () => dispatch(openDeleteModalAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(scriptLoader('https://js.stripe.com/v3/')(Settings));
