import React, {Component} from "react";
import {animateScroll} from "react-scroll";
import {
    checkSessionValidityAction
} from "../../actions/authActions";
import {
    addHighlightAction,
    addNoteToMeetingAction,
    getAllLiveMeetingDataAction,
    getLiveMeetingDataAction,
    stopTriggerAction
} from "../../actions/meetingActions";
import {connect} from "react-redux";
import * as Constants from "../../components/Common";
import LiveTranscript from "../../components/LiveTranscript/container/LiveTranscript";
import Tab from "../../components/Tab/container/Tab";
import addIcon from "../../assets/add-icon.png";
import io from 'socket.io-client';
import EditTranscriptModal from "../../components/EditTranscriptModal/container/EditTranscriptModal";
import Loader from "react-loader-spinner";

import "./LiveMeeting.css";

class LiveMeeting extends Component {
    constructor() {
        super();

        this.state = {
            count: 5,
            running: false,
            active: "transcript",
            customNoteInput: "",
            dropDownMenuVisible: true,
            changeDetected: false,
            windowWidth: window.innerWidth,
            meetingTranscripts: []
        };
        this.ref = React.createRef();

        this.update = this.update.bind(this);
        const serverUrl = "wss://transcribe.live.zoimeet.com";
        //const serverUrl = 'http://localhost:4001';
        this.socket = io(serverUrl);

    }

    componentDidMount() {
        const queryString = window.location.search;
        // console.log(queryString);
        const urlParams = new URLSearchParams(queryString);
        const pin = urlParams.get('pin');
        const id = urlParams.get('id');
        console.log(pin, id);
        window.addEventListener("resize", this.handleResize);
        if (localStorage.getItem("authToken") !== null) {
            this.props.checkSessionValidity();
            if (this.props.location.search !== null) {
                this.pin = pin;
                this.props.getAllLiveMeetingData(id);
                this.handleStart(id);
            }
        } else
            window.location.href = (Constants.login_url);
    }

    handleStart(id) {
        console.log(this.pin);

        this.socket.on('connect', () => {
            this.socket.emit('register', {"meetingId": this.pin});
        });
        this.socket.on('update', () => {
            this.props.getLiveMeetingData(id);
            this.scrollToBottom()
        });
    }

    handleResize = (e) => {
        this.setState({windowWidth: window.innerWidth})
    };

    update = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({[name]: value});
    };

    scrollToBottom() {
        animateScroll.scrollToBottom({
            containerId: "tab-section-outer"
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.liveMeeting.highlightRequest) this.scrollToBottom();
        if (prevProps.liveMeeting.liveMeetingLoading) this.scrollToBottom();

    }

    addAHighlight = () => {
        this.props.addHighlight(localStorage.getItem("liveMeetingID"));
        //this.props.getAllLiveMeetingData(localStorage.getItem("liveMeetingId"));
    };

    addNote = () => {
        this.props.addNoteToMeeting(localStorage.getItem("liveMeetingID"), this.state.customNoteInput);
        this.setState({customNoteInput: ""});
    };

    render() {
        const {active, windowWidth} = this.state;
        const {liveMeeting, widget} = this.props;

        return (
            <div className="live-meeting-outer-container">

                {(liveMeeting.showEditModalLive) &&
                <EditTranscriptModal
                    type={"live"}
                />
                }

                {false && windowWidth > 1080 &&
                <div className="video-container">
                </div>
                }

                <div className="meeting-container-mobile">

                    <div className={windowWidth > 1080 ? "tab-container-mobile" : "tab-container-mobile"}>

                        <Tab
                            activeTab={active}
                            type={"highlights"}
                        />
                        <Tab
                            activeTab={active}
                            type={"transcript"}
                        />

                        {false &&
                        <Tab
                            activeTab={active}
                            type={"settings"}
                        />
                        }
                    </div>

                    <div
                        id="tab-section-outer"
                        className={windowWidth > 1080 ? "tab-section-outer-mobile" : "tab-section-outer-mobile"}>
                        {(widget.activeTab.activeTab === "transcript" && !liveMeeting.highlightRequest) &&
                        (liveMeeting.liveMeetingTranscripts.map((key, i) => {

                                return (
                                    <div
                                        id={key["id"]}
                                    >
                                        <LiveTranscript
                                            commentText={""}
                                            onClick={() => this.props.openEditModal(key["id"], key["fk_meeting"], key["data"])}
                                            category={key["category"]}
                                            createdTime={key["created_time"]}
                                            startTime={key["start_time"]}
                                            highlighted={key["highlighted"]}
                                            msgID={key["id"]}
                                            meetingID={key["fk_meeting"]}
                                            data={key["data"]}
                                            searchWord={""}
                                            searchResult={false}
                                            speakerLabel={key["speaker_label"]}
                                        />
                                    </div>
                                )
                            }
                        ))
                        }

                        {(widget.activeTab.activeTab === "highlights" && (liveMeeting.updateTriggered || liveMeeting.liveMeetingTranscripts.length > 0)) &&
                        (liveMeeting.liveMeetingTranscripts.map((key, i) => {
                                return (
                                    (key["category"] === 2 || key["category"] === 3 || key["category"] === 4) ?
                                        <div
                                            id={key["id"]}
                                        >
                                            <LiveTranscript
                                                type={""}
                                                onClick={() => this.props.openEditModal(key["id"], key["fk_meeting"], key["data"])}
                                                category={key["category"]}
                                                createdTime={key["created_time"]}
                                                startTime={key["start_time"]}
                                                highlighted={key["highlighted"]}
                                                msgID={key["id"]}
                                                meetingID={key["fk_meeting"]}
                                                data={key["data"]}
                                                searchWord={""}
                                                searchResult={false}
                                                speakerLabel={key["speaker_label"]}
                                            />
                                        </div>
                                        :
                                        null
                                )
                            }
                        ))
                        }

                        {(liveMeeting.liveMeetingTranscripts.length < 1 && widget.activeTab.activeTab === "transcript") &&
                        <p className="live-meeting-waiting-message-transcript">
                            Your AI Assistant is calling in, please make sure to accept the AI into the meeting.
                            Your transcripts will show up as soon as the AI Assistant joins.

                            <Loader
                                type="ThreeDots"
                                color="#000"
                                height={20}
                                width={40}
                            />
                        </p>
                        }
                        {(widget.activeTab.activeTab === "highlights" && liveMeeting.noHighlights) &&
                        <p className="live-meeting-waiting-message">

                            Once your AI Assistant has joined your meeting, you can capture meeting highlights in
                            the following way:<br/><br/>

                            1. By saying your highlight trigger words you can automatically capture important
                            decisions, highlights, and notes.<br/><br/>

                            2. Click on the "Capture Highlight" button to capture whatever is being said right
                            after clicking the button.<br/><br/>

                            3. Add a custom note by typing in important highlights in the text field at the bottom
                            of this panel and click “Add note” to save.<br/><br/>

                            4. Within your live transcript you can click on the dropdown icon to add that moment
                            to your highlights as an action, decision, or note.
                        </p>
                        }
                    </div>

                    <div className="live-actions-div">

                        <input
                            className={windowWidth > 1080 ? "add-note-input" : "add-note-input-mobile"}
                            name="customNoteInput"
                            onChange={this.update}
                            placeholder="Add your notes or additional thoughts here..."
                            type="text"
                            value={this.state.customNoteInput}
                        />

                        <div className="live-actions-buttons-div">

                            <button
                                className="add-highlight-button btn btn-orange btn-lg btn-lg-h fw-b f-14"
                                onClick={() => this.addAHighlight()}
                            >
                                <img src={addIcon} alt="Add Highlight" className="add-highlight"/>
                                Capture Highlight
                            </button>
                            <button
                                className={this.state.customNoteInput === "" ? "add-note-button-disabled btn btn-lg btn-lg-h fw-b f-14" : "add-note-button btn btn-lg btn-lg-h fw-b f-14"}
                                disabled={this.state.customNoteInput === ""}
                                onClick={() => this.addNote()}
                            >
                                Add Note
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    dropDown: state.dropDown,
    meeting: state.meeting,
    liveMeeting: state.liveMeeting,
    widget: state.widget
});

const mapDispatchToProps = (dispatch) => ({
    stopTrigger: () => dispatch(stopTriggerAction()),
    checkSessionValidity: () => dispatch(checkSessionValidityAction()),
    getAllLiveMeetingData: (meetingID) => dispatch(getAllLiveMeetingDataAction(meetingID)),
    getLiveMeetingData: (meetingID) => dispatch(getLiveMeetingDataAction(meetingID)),
    addHighlight: (meetingID) => dispatch(addHighlightAction(meetingID)),
    addNoteToMeeting: (meetingID, note) => dispatch(addNoteToMeetingAction(meetingID, note))
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveMeeting);